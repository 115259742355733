import React from 'react';
import '../resume_style/resume_full.scss';

const Resume = () => (
  <section id="resume" className="page">
    <header className="resume-header clearfix">
        <div className="profile-header pull-left">
            <h1>Geri Rosenberg</h1>
            <h2>GIS Analyst and Planner</h2>
        </div>
        <div className="profile-pic pull-right">
          <h3><a href="resume.pdf">Download PDF</a></h3>
        </div>
    </header>
    <div className="resume-content">
      <aside className="left-column">
        <div className="container about-container">
            <div className="title">
                <h3>About</h3>
                <div className="keyline"></div>
            </div>
            < div className="item-about">
            <div className="info-tag-container">
                <i className="fa fa-map-marker"></i>
            
                    <h6 className="info-text">38A Sharon St <br />
                    San Francisco, CA 94114</h6>
            </div>
          </div>
    
            <div className="info-tag-container">
                <i className="fa fa-envelope-o"></i>
            
                    <h6 className="info-text">
                        <a href="mailto:geri@itsgeri.com" target="_blank">
                            geri@gisgeri.com
                        </a>
                    </h6>
            </div>
    
            <div className="info-tag-container">
                <i className="fa fa-mobile"></i>
            
                    <h6 className="info-text">(410) 336-5550</h6>
            </div>

            <div className="info-tag-container">
                <i className="fa fa-globe"></i>
            
                    <h6 className="info-text">
                        <a href="http://www.gisgeri.com" target="_blank">
                            www.gisgeri.com
                        </a>
                    </h6>
            </div>
    

            <div className="info-tag-container">
                <i className="fa fa-linkedin-square"></i>
            
                    <h6 className="info-text">
                        <a href="https://www.linkedin.com/in/geri-rosenberg/" target="_blank">
                            gerirosenberg
                        </a>
                    </h6>
            </div>
             <div className="info-tag-container">
                 <i className="fa fa-github"></i>
             
                     <h6 className="info-text">
                         <a href="https://github.com/gerirosenberg" target="_blank">
                             gerirosenberg
                         </a>
                     </h6>
             </div>
        </div>
            <div className="skills-container">
                    <div className="container">
                            <div className="title">
                                <h3>GIS</h3>
                                <div className="keyline"></div>
                            </div>                    <h4 className="bold capitalize">Expert</h4>
                            <div className="minimal flex-container">
                                    <h6 className="main-skill skill">ArcGIS Pro</h6>
                                    <h6 className="main-skill skill">ArcGIS Online</h6>
                                    <h6 className="main-skill skill">QGIS</h6>
                            </div>
                    </div>
                    <div className="container">
                            <div className="title">
                                <h3>Cartography</h3>
                                <div className="keyline"></div>
                            </div>                    <h4 className="bold capitalize">Expert</h4>
                            <div className="minimal flex-container">
                                    <h6 className="main-skill skill">Illustrator</h6>
                                    <h6 className="main-skill skill">InDesign</h6>
                            </div>
                    </div>
                    <div className="container">
                            <div className="title">
                                <h3>Geocomputing</h3>
                                <div className="keyline"></div>
                            </div>                    <h4 className="bold capitalize">Expert</h4>
                            <div className="minimal flex-container">
                                    <h6 className="main-skill skill">GDAL &amp; OGR</h6>
                                    <h6 className="main-skill skill">Python</h6>
                            </div>
                    </div>
                    <div className="container">
                            <div className="title">
                                <h3>Web Development</h3>
                                <div className="keyline"></div>
                            </div>                    <h4 className="bold capitalize">Expert</h4>
                            <div className="minimal flex-container">
                                    <h6 className="main-skill skill">CSS</h6>
                                    <h6 className="main-skill skill">Git</h6>
                                    <h6 className="main-skill skill">HTML5</h6>
                                    <h6 className="main-skill skill">Javascript</h6>
                            </div>
                    </div>
                    <div className="container">
                            <div className="title">
                                <h3>Databases</h3>
                                <div className="keyline"></div>
                            </div>                    <h4 className="bold capitalize">Intermediate</h4>
                            <div className="minimal flex-container">
                                    <h6 className="main-skill skill">MySQL</h6>
                                    <h6 className="main-skill skill">PostGIS</h6>
                                    <h6 className="main-skill skill">PostGreSQL</h6>
                            </div>
                    </div>
                    <div className="container">
                            <div className="title">
                                <h3>Javascript Libraries</h3>
                                <div className="keyline"></div>
                            </div>                    <h4 className="bold capitalize">Intermediate</h4>
                            <div className="minimal flex-container">
                                    <h6 className="main-skill skill">jQuery</h6>
                                    <h6 className="main-skill skill">Leaflet</h6>
                                    <h6 className="main-skill skill">Mapbox</h6>
                                    <h6 className="main-skill skill">OpenStreetMap</h6>
                                    <h6 className="main-skill skill">ReactJS</h6>
                            </div>
                    </div>
            </div>
      </aside>
      <div className="right-column">
        <div className="container education-container">
            <div className="title">
            <h3>Education</h3>
            <div className="keyline"></div>
        </div> 
            <div className="item">
                <div className="div-header clearfix">
            <h3 className="bold pull-left">
                    University of Wisconsin- Madison
            </h3>
            <h5 className="italic pull-right">
                    <span className="startDate">08/2018</span>
                    <span className="endDate"> - 08/2019</span>
            </h5>
        </div>
         
                <h4>Certificate, Advanced GIS and Web Map Programming</h4>
        
          
                <ul className="two-column">
                    <li>Cartography</li>
                    <li>Geocomputing & Spatial Analysis</li>
                    <li>Databases</li>
                    <li>Interactive Visualizations</li>
                </ul>
            </div>
        
            <div className="item">
                <div className="div-header clearfix">
            <h3 className="bold pull-left">
                    Wesleyan University (Middletown, CT)
            </h3>
            <h5 className="italic pull-right">
                    <span className="startDate">08/2008</span>
                    <span className="endDate"> - 05/2012</span>
            </h5>
        </div>
         
                <h4>B.A. American Studies, Psychology</h4>
        
         
                <h5 className="specialization">Urban History and Development</h5>
                     </div>
        </div>
        <div className="container work-container">
            <div className="title">
                <h3>Experience</h3>
                <div className="keyline"></div>
            </div>
                <div className="item">
                    <div className="div-header clearfix">
                        <h3 className="bold pull-left">
                                Cambridge Systematics
                        </h3>
                        <h5 className="italic pull-right">
                                <span className="startDate">07/2019</span>
                                <span className="endDate"> - 03/2020</span>
                        </h5>
                    </div>
        
                    <h4>Transportation Analyst, Planning</h4>
        
        
        
                    <ul>
                        <li>Deputy project manager for $1M FTA contract; directed team through complex Federal security process and trained colleagues in expansive database access, organization, and queries</li>
                        <li>Developed <a href="http://www.drpt.virginia.gov/media/3263/drpt-eit-in-va-executive-summary_final_112320.pdf">analysis strategy</a> for economic data from 41 transit agencies in Virginia, accounting for 175M annual ridership, $575M+ in payroll, and $480M+ in capital spending</li>
                        <li>Collected, segmented, and analyzed data for state DOT performance measures (with focus on road safety); led QA/QC and developed static visualizations and maps for <a href="http://oipi.virginia.gov/programs/performance-measures/default.asp">reports</a></li>
                    </ul>
                </div>
                <div className="item">
                    <div className="div-header clearfix">
                        <h3 className="bold pull-left">
                                Smart Growth America
                        </h3>
                        <h5 className="italic pull-right">
                                <span className="startDate">04/2016</span>
                                <span className="endDate"> - 03/2019</span>
                        </h5>
                    </div>
        
                    <h4>Program Associate, Economic Development</h4>
        
        
        
                    <ul>
                        <li>Deputy project manager for $2M FTA transit-oriented development technical assistance contract; compiled quarterly financial and descriptive reports and led call agendas</li>
                        <li>Surveyed real estate/land use and developed maps for 20+ technical assistance recipients, focused in transit-oriented development and downtown redevelopment</li>
                        <li>Built from scratch a two-part study tour showcasing downtown redevelopment and talent attraction innovation, in <a href="https://smartgrowthamerica.org/amazing-place-communities-find-inspiration-mile-high-city/">Denver</a> and <a href="https://smartgrowthamerica.org/amazing-place-greenville/">Greenville, SC</a></li>
                        <li>Researched for and co-authored <a href="https://smartgrowthamerica.org/app/uploads/2017/04/rural-toolkit_affordable-housing.pdf">rural affordable housing toolkit</a></li>
                    </ul>
                </div>
                <div className="item">
                    <div className="div-header clearfix">
                        <h3 className="bold pull-left">
                                Communities for Transit
                        </h3>
                        <h5 className="italic pull-right">
                                <span className="startDate">08/2013</span>
                                <span className="endDate"> - 03/2016</span>
                        </h5>
                    </div>
        
                    <h4>Project Manager</h4>
        
        
        
                    <ul>
                        <li>Mobilized public education campaign for the planned Bus Rapid Transit System (BRT) in Montgomery County, MD &amp; recruited coalition of supporters</li>
                        <li>Developed <a href="https://www.smartergrowth.net/wp-content/uploads/2015/08/2015-BRT-best-practices-guide.pdf">best practices BRT design guide</a> for stakeholders &amp; community</li>
                    </ul>
                </div>
        </div>
        <div className="container awards-container">
            <div className="title">
                <h3>Accomplishments</h3>
                <div className="keyline"></div>
            </div>
                <div className="item">
                    <div className="div-header clearfix">
                        <h3 className="bold pull-left">
                                First Place, Group Student Dynamic Map Competition (2019)
                        </h3>
                        <h5 className="italic pull-right">
                        </h5>
                    </div>
        
                    <h5 className="awarder">North American Cartographic Information Society (NACIS)</h5>
        
                </div>
                <div className="item">
                    <div className="div-header clearfix">
                        <h3 className="bold pull-left">
                                Colorado Trail Thru-Hiker
                        </h3>
                        <h5 className="italic pull-right">
                        </h5>
                    </div>

                    <h5 className="awarder">Solo-backpacked 485 miles between Denver and Durango in 24 days</h5>

                </div>
        </div>
        <div className="container publications-container">
            <div className="title">
                <h3>Work Samples</h3>
                <div className="keyline"></div>
            </div>
            <div className="item">
                <div className="div-header clearfix">
                    <h3 className="bold pull-left">
                            <a href="https://gerirosenberg.github.io/careless-decay/" target="_blank">
                                Careless Decay: Dental Deserts in the United States
                            </a>
                    </h3>
                    <h5 className="italic pull-right">
                    </h5>
                </div>
        
        
                <p className="summary">Interactive story map awarded first place in NACIS competition</p>
        
            </div>
            <div className="item">
                <div className="div-header clearfix">
                    <h3 className="bold pull-left">
                            <a href="https://gerirosenberg.github.io/d3-coordinated-viz/" target="_blank">
                                How the D.C. Region Gets to Work
                            </a>
                    </h3>
                    <h5 className="italic pull-right">
                    </h5>
                </div>
        
        
                <p className="summary">Interactive D3 map and graph from census data</p>
        
            </div>
        </div>
      </div>
    </div>
  </section>
)
export default Resume;